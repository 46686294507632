.signature {
  width: 840px;
  height: 273px;
  margin: 0 0 $l 0;

  @media screen and (max-width: 940px) {
    width: 90%;
    height: 200px;
  }

  @media screen and (max-width: 800px) {
    height: 140px;
  }
}
