.articles-recommendation {
  padding: $xxl 0;
  width: 100%;
  background-color: $whiteLight;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > h4 {
    max-width: 80%;
    width: 100%;
    color: $brown;
    margin: 0 auto $xl auto;
    text-align: center;
    @include applyFontSchema($displaySmall, $pfdFamily, $bold);

    @media screen and (max-width: 380px) {
      font-size: 28px;
    }

    @media screen and (max-width: 1040px) {
      max-width: 90%;
    }
  }

  &__content {
    max-width: 80%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, auto);
    margin: 0 auto;
    row-gap: $l;
    column-gap: $xl;

    @media screen and (max-width: 1820px) {
      grid-template-columns: repeat(3, auto);
    }

    @media screen and (max-width: 1370px) {
      grid-template-columns: repeat(2, auto);
    }

    @media screen and (max-width: 1040px) {
      max-width: 90%;
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(1, auto);
    }

    & > .article {
      transform: unset;
      rotate: unset;
      display: flex !important;
      margin: 0 auto !important;
    }
  }
}
