.splash-loader {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__progress {
    width: fit-content;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    padding: $sm $xxxl;
    background-color: $brown;
    border-radius: 0 0 $c $c;
    color: $whiteLight;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: 1.5s fadeInToBottom ease-in-out;

    @media screen and (max-width: 630px) {
      flex-direction: column;
      border-radius: $zero;
      width: 100%;
    }

    &.animation-back {
      animation: 1.5s fadeInToBottomReversed ease-in-out;
    }
  }

  &__loader {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    max-height: 95%;
    top: 0;
    animation: 1.5s fadeIn ease-in-out;

    &.animation-back {
      animation: 1.5s fadeOut ease-in-out;
    }
  }

  &__value {
    @include applyFontSchema($titleMedium, $mulishFamily, $medium);

    @media screen and (max-width: 630px) {
      margin: 0 0 $xs 0;
      @include applyFontSchema($titleSmall, $mulishFamily, $medium);
    }
  }

  &__separator {
    margin: $zero $sm;
    @include applyFontSchema($titleMedium, $mulishFamily, $medium);

    @media screen and (max-width: 630px) {
      display: none;
    }
  }

  &__label {
    @include applyFontSchema($titleMedium, $mulishFamily, $medium);
    margin: 0 $xs 0 0;

    @media screen and (max-width: 630px) {
      margin: 0 0 $s 0;
      text-align: center;
      @include applyFontSchema($titleSmall, $mulishFamily, $medium);
    }
  }

  &__mn {
    width: 21px;
    height: 21px;
  }
}
