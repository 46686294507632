.faq-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid $spaceWhite;
  border-radius: $s;
  overflow: hidden;
  margin: 0 0 $m 0;

  animation: fadeInToRight 1.5s ease-in-out;

  &:nth-child(1) {
    animation: fadeInToRight 0.5s ease-in-out;
  }

  &:nth-child(2) {
    animation: fadeInToRight 1s ease-in-out;
  }

  &:last-child {
    margin: 0;
  }

  & > header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    padding: $l;

    & > h2 {
      color: $brown;
      @include applyFontSchema($titleMedium, $mulishFamily, $medium);
      font-size: 18px;
    }

    & > img {
      width: 32px;
      height: 32px;
      transition: all 0.7s ease-in-out;

      &.expanded {
        rotate: 180deg;
      }
    }
  }

  & > section {
    padding: 0 $l $l $l;

    & p,
    & i,
    & li {
      width: 100%;
      text-align: left;
      color: $brown;
      margin: 0 auto $l auto;
      @include applyFontSchema($titleSmall, $mulishFamily, $regular);
    }

    & ul li {
      list-style-type: '●  ';
      margin: 0 auto $sm $l;
    }

    & b {
      width: 100%;
      text-align: left;
      color: $brown;
      margin: 0 auto $l auto;
      @include applyFontSchema($titleSmall, $mulishFamily, $bold);
    }

    & p:last-child,
    & b:last-child {
      margin: 0;
    }
  }
}
