.content-header {
  width: 100%;
  max-width: 80%;
  margin: 0 auto $xxxl auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;

  @media screen and (max-width: 760px) {
    max-width: 90%;
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }

  &__left-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 820px) {
      align-items: center;
    }

    &.animation {
      animation: fadeInToRight 0.8s ease-in-out;
    }

    & > h3 {
      color: $black;
      text-transform: capitalize;
      margin: 0 0 0 87px;
      @include applyFontSchema($headlineLarge, $pfdFamily, $regular);

      @media screen and (max-width: 820px) {
        margin: 0 0 $l 0;
      }
    }
  }

  &__sub-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    @media screen and (max-width: 820px) {
      flex-direction: column;
    }

    hr {
      width: $xc;
      border-top: 1px solid $brownLight;
      margin: 0 $l 0 0;

      @media screen and (max-width: 820px) {
        margin: 0 auto $sm auto;
      }
    }

    & > h4 {
      color: $brownLight;
      text-transform: uppercase;
      @include applyFontSchema($titleLarge, $mulishFamily, $regular);
    }
  }
}
