.rate {
  unicode-bidi: bidi-override;
  color: #ccc;
  font-size: 64px;
  position: relative;
  margin: 0;
  padding: 0;
  width: fit-content;

  &__active {
    color: $yellow;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
    font-size: 18px;

    & > span {
      display: inline-block;
    }
  }

  &__layout {
    padding: 0;
    display: block;
    z-index: 0;
    font-size: 18px;
  }
}
