.search-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 42px;
  padding: $sm;
}
