.share {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: $l;
  bottom: $l;
  border-radius: 1000px;
  animation: 1.2s fadeInToRight ease-in-out;
  box-shadow: 0px 0px 8px #13131257;

  & > img {
    cursor: pointer;
  }

  &__scp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 82px;
    bottom: $l;
    background-color: $whiteLight;
    padding: $sm;
    border-radius: $l;
    box-shadow: 0px 0px 8px #13131257;

    animation: 0.3s fadeInToRightSlow ease-in-out;

    @media screen and (max-width: 700px) {
      left: 24px;
      bottom: 80px;
      flex-direction: column-reverse;
      animation: 0.3s fadeInToTopForServiceItem ease-in-out;
    }

    &__items {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }

      & > button {
        margin: 0 $l 0 0;

        @media screen and (max-width: 700px) {
          margin: 0 0 $m 0;
        }
      }

      & > button,
      & > img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }

  &__triangle {
    width: 5px;
    height: 10px;
    position: relative;
    right: 21px;
    rotate: -90deg;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $whiteLight;

    @media screen and (max-width: 700px) {
      left: 0px;
      top: 21px;
      rotate: 180deg;
    }
  }
}
