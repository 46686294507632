.welcome {
  width: 100%;
  height: 100vh;
  animation: fadeIn 0.7s ease-in-out;
  overflow-x: hidden;

  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    overflow-x: hidden;
  }

  &__video-layout {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    overflow-x: hidden;

    & > video {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      object-fit: cover;
      object-position: center;
    }
  }

  &__video-backdrop {
    width: 100%;
    height: 100%;
    background-color: $brown;
    position: relative;
    z-index: 2;
    opacity: 0.7;
  }

  &__fg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    z-index: 3;

    & > .button-sba {
      width: 290px;
      height: 51px;
    }

    & > h3 {
      animation: 1.5s fadeInToLeft ease-out;
      color: $white;
      margin: 0 0 $xl 0;
      text-transform: uppercase;

      @include applyFontSchema($titleLarge, $pfdFamily, $regular);

      @media screen and (max-width: 580px) {
        @include applyFontSchema($titleMedium, $pfdFamily, $regular);
      }
    }
  }
}

.properties,
.services {
  padding: $xxxl 0;
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 620px) {
    padding: $xl 0;
  }
}

.services {
  position: relative;
  z-index: 0;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__bg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    z-index: 1;
    position: relative;
    margin: 0 auto 196px auto;

    & > img {
      margin: 0 auto;
      border-radius: $l;

      &.animation {
        animation: fadeIn 1s ease-in-out;
      }
    }
  }

  &__fg {
    position: absolute;
    bottom: $xc;
    z-index: 3;
    margin: 0 7.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: $l;

    @media screen and (max-width: 1200px) {
      margin: 0 2.5%;
      column-gap: $m;
    }

    @media screen and (max-width: 1140px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: $m;
    }

    @media screen and (max-width: 580px) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (max-width: 420px) {
      margin: 0;
    }
  }
}

.subscribe {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > img {
    width: 100%;
    height: 100vh;
    background-color: $brown;
    position: absolute;
    object-fit: cover;
  }

  &__header {
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $whiteLight;
  }

  &__title {
    text-transform: uppercase;
    margin: 0 0 $l 0;

    @include applyFontSchema($headlineSmall, $mulishFamily, $regular);

    &.animation {
      animation: fadeInToRight 1s ease-in-out;
    }
  }

  &__subtitle {
    margin: 0 0 $xl 0;
    text-align: center;
    white-space: pre-line;

    @include applyFontSchema($displayMedium, $pfdFamily, $bold);
    padding: 0 $l;
    line-height: unset;

    @media screen and (max-width: 680px) {
      padding: 0 $l;
      @include applyFontSchema($displaySmall, $pfdFamily, $bold);
      white-space: unset;
    }

    &.animation {
      animation: fadeInToLeft 1s ease-in-out;
    }
  }

  &__form {
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 363px;
    background-color: $whiteLight;
    padding: $l;
    border-radius: $m;

    &.animation {
      animation: fadeInToTop 1s ease-in-out;
    }

    @media screen and (max-width: 420px) {
      padding: $m;
      max-width: 90%;
    }
  }

  & .default-filled-button {
    width: 100%;
    @include applyFontSchema($displayMedium, $mulishFamily, $bold);
  }
}

.reviews {
  padding: $xxxl 0;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &__content {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, auto);
    row-gap: $l;
    column-gap: $l;
    max-width: 80%;

    @media screen and (max-width: 1530px) {
      max-width: 90%;
      row-gap: $xl;
      column-gap: $sm;
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, auto);
      column-gap: $l;
    }

    @media screen and (max-width: 760px) {
      grid-template-columns: repeat(1, auto);
      max-width: 100%;
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: repeat(1, auto);
      max-width: 80%;
    }
  }
}

.priority-news {
  padding: $xxxl 0 0 0;
  width: 100%;
  margin: 0 0 -1px 0;
  overflow: hidden;
  position: relative;
  z-index: 1;

  & > .content-header {
    margin: 0 auto;

    & .content-header__left-side {
      & > h3 {
        @media screen and (max-width: 960px) {
          margin: 0 0 24px 0;
        }
      }
    }

    & .content-header__sub-title {
      @media screen and (max-width: 960px) {
        flex-direction: column;
      }

      hr {
        width: $xc;
        border-top: 1px solid $brownLight;
        margin: 0 $xxl 0 0;

        @media screen and (max-width: 960px) {
          margin: 0 auto $sm auto;
        }
      }
    }
  }

  &__content {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    justify-items: center;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    position: relative;
    z-index: 4;
    bottom: -235px;
    max-height: 335px;
    height: 335px;

    @media screen and (max-width: 1770px) {
      max-width: 90%;
    }

    @media screen and (max-width: 1550px) {
      max-width: 100%;
    }

    @media screen and (max-width: 1020px) {
      margin: -135px 0 0 0;
    }
  }

  &__bottom {
    position: relative;
    z-index: 1;

    & > div {
      &:first-child {
        width: 120vw;
        height: 252px;
        margin: 0 0 0 -10vw;
        background-color: $brown;
        border-radius: 50% 50% 0 0;
        position: relative;
        z-index: 2;
      }

      &:last-child {
        height: 161px;
        background-color: $brown;
        position: relative;
        z-index: 3;
        margin: -1px 0 0 0;
        padding: 60px 0 0 0;

        & > .button-sba {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          align-content: center;
          max-width: 325px;
          height: 52px;
          margin: 0 auto;

          @media screen and (max-width: 620px) {
            max-width: 80%;
          }
        }
      }
    }
  }
}

.why-is {
  padding: $xxxl 0;
  width: 100%;
  overflow: hidden;
  background-color: $brown;

  @media screen and (max-width: 1220px) {
    padding: $l 0;
  }

  &__layout {
    display: flex;
    flex-direction: row;
    max-width: 80%;
    margin: 120px auto;
    justify-content: space-between;

    @media screen and (max-width: 1750px) {
      max-width: 90%;
    }

    @media screen and (max-width: 1590px) {
      max-width: 95%;
    }

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: $xl auto $xl auto;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;

    @media screen and (max-width: 1220px) {
      align-items: center;
      justify-content: center;
    }

    &.animation {
      animation: fadeInToRight 0.7s ease-in-out;
    }

    & .content-header {
      max-width: 100%;

      margin: 0 auto;

      @media screen and (max-width: 1220px) {
        margin: 0 auto $xxl auto;
        flex-direction: column;
      }

      & h3 {
        color: $whiteLight;

        @media screen and (max-width: 1220px) {
          margin: 0;
        }
      }

      .content-header__sub-title {
        @media screen and (max-width: 1220px) {
          flex-direction: column;
        }

        & > hr {
          @media screen and (max-width: 1220px) {
            margin: 0 0 $m 0;
          }
        }
      }
    }
  }

  &__content {
    @media screen and (max-width: 1220px) {
      margin: 0 0 $l 0;
      display: flex;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    & > p {
      width: 100%;
      max-width: 70%;
      color: $whiteLight;
      margin: 0 0 $l 0;
      padding: 0 0 0 100px;

      @include applyFontSchema($titleMedium, $mulishFamily, $medium);

      @media screen and (max-width: 1220px) {
        &:last-child {
          margin: 0 0 $l 0;
        }
      }

      &:last-child {
        margin: 0;
      }

      @media screen and (max-width: 1750px) {
        max-width: 80%;
      }

      @media screen and (max-width: 1520px) {
        padding: 0;
      }

      @media screen and (max-width: 520px) {
        margin: 0 auto $l auto;
      }
    }
  }

  &__owner {
    position: relative;
    z-index: 1;

    &.animation {
      animation: fadeInToLeft 0.7s ease-in-out;
    }

    & > img {
      @media screen and (max-width: 820px) {
        object-fit: contain;
        width: 100%;
        margin-bottom: $c;
      }
    }

    & .signature {
      width: 387px;
      height: 108px;
      bottom: 30px;
      left: 50%;
      position: absolute;
      z-index: 2;
      margin: 0;
      rotate: (-8.44deg);

      @media screen and (max-width: 1610px) {
        left: 45%;
      }

      @media screen and (max-width: 1560px) {
        left: 43%;
      }

      @media screen and (max-width: 1220px) {
        left: 50%;
      }

      @media screen and (max-width: 800px) {
        max-width: 50%;
        width: 100%;
      }
    }

    & > img {
      object-fit: fill;
      border-radius: $m;

      @media screen and (max-width: 1380px) {
        top: 55%;
        object-fit: cover;
      }

      @media screen and (max-width: 630px) {
        top: 80%;
      }
    }
  }

  &__forward {
    width: 230px;
    height: 230px;
    position: absolute;
    z-index: 1;
    left: -114px;
    top: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: row;
    border: 1px solid $yellow;
    border-radius: 100rem;
    cursor: pointer;
    padding: $m;

    @media screen and (max-width: 1340px) {
      left: 5%;
      top: 15px;
    }

    @media screen and (max-width: 620px) {
      width: 12rem;
      height: 12rem;
    }

    @media screen and (max-width: 540px) {
      width: 10rem;
      height: 10rem;
    }

    @media screen and (max-width: 420px) {
      width: 7rem;
      height: 7rem;
    }

    @media screen and (max-width: 320px) {
      width: 6rem;
      height: 6rem;
    }

    & > img {
      @media screen and (max-width: 620px) {
        display: none;
      }
    }

    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-direction: row;

      color: $whiteLight;
      text-align: center;

      text-transform: uppercase;
      @include applyFontSchema($headlineSmall, $mulishFamily, $regular);

      @media screen and (max-width: 540px) {
        @include applyFontSchema($labelLarge, $mulishFamily, $regular);
      }
    }
  }
}
