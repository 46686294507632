.article-content {
  width: 100%;
  border-radius: $m;
  max-width: 1000px;
  margin: 120px auto;
  text-align: left;

  @media screen and (max-width: 1000px) {
    margin: 120px auto 60px auto;
  }

  & > header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto $m auto;

    @media screen and (max-width: 650px) {
      flex-direction: column-reverse;
    }

    .button-icon {
      @media screen and (max-width: 650px) {
        margin: 0 0 $l 0;
      }
    }

    @media screen and (max-width: 1000px) {
      max-width: 90%;
    }

    &.animation {
      animation: fadeInToRight 0.8s ease-in-out;
    }
  }

  &__category {
    color: $yellow;

    margin: 0 $xl 0 0;
    @include applyFontSchema($titleMedium, $mulishFamily, $bold);
  }

  &__published {
    color: $brownLight;

    @include applyFontSchema($titleMedium, $mulishFamily, $medium);
  }

  & > h1 {
    color: $brown;

    margin: 0 auto $l auto;
    @include applyFontSchema($displaySmall, $pfdFamily, $bold);

    @media screen and (max-width: 1000px) {
      max-width: 90%;
    }

    &.animation {
      animation: fadeInToRight 0.8s ease-in-out;
    }
  }

  &__cover {
    object-fit: contain;
    width: 100%;
    height: 100%;
    margin: 0 0 $m 0;
    border-radius: $m;

    @media screen and (max-width: 1000px) {
      border-radius: 0;
    }

    &.animation {
      animation: fadeInToRight 0.8s ease-in-out;
    }
  }

  &__data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.animation {
      animation: fadeIn 1.5s ease-in-out;
    }

    & p,
    & i {
      width: 100%;
      text-align: left;
      color: $brownDark;

      margin: 0 auto $l auto;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);

      @media screen and (max-width: 1000px) {
        max-width: 90%;
      }
    }

    & b {
      width: 100%;
      text-align: left;
      color: $brownDark;

      margin: 0 auto $l auto;
      @include applyFontSchema($bodyLarge, $mulishFamily, $bold);

      @media screen and (max-width: 1000px) {
        max-width: 90%;
      }
    }

    & a {
      color: $info;
      cursor: pointer;
      text-decoration: underline;
      word-break: break-all;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
    }

    & video,
    & img {
      object-fit: contain;
      width: 100%;
      aspect-ratio: 16 / 9;
      margin: 0 0 $m 0;
      border-radius: $m;

      @media screen and (max-width: 1000px) {
        border-radius: 0;
      }
    }

    & img {
      width: fit-content !important;
      aspect-ratio: unset;
      max-width: 100%;
    }

    & h3 {
      width: 100%;
      text-align: left;
      color: $brown;

      margin: $xl auto $m auto;
      @include applyFontSchema($headlineSmall, $pfdFamily, $bold);

      @media screen and (max-width: 1000px) {
        max-width: 90%;
      }
    }

    & p:last-child,
    & i:last-child {
      margin: 0;
    }
  }
}
