.faq {
  width: 100%;
  height: 100vh;
  max-height: 685px;
  animation: fadeIn 0.7s ease-in-out;

  @media screen and (max-width: 600px) {
    margin: 0 0 $s 0;
  }

  &__bg {
    width: 100%;
    height: 100%;
    max-height: 685px;
    position: absolute;
    z-index: 1;
    border-bottom: 1px solid $brown;

    & .town-faq {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__fg {
    width: 100%;
    height: 100%;
    max-height: 685px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    z-index: 2;

    & > h1 {
      width: 100%;
      text-align: center;
      padding: 0 $l;
      color: $brown;
      margin: 0 0 8px 0;
      @include applyFontSchema($displayMedium, $pfdFamily, $bold);

      @media screen and (max-width: 400px) {
        @include applyFontSchema($displaySmall, $pfdFamily, $bold);
      }
    }
  }
}

.faq-content {
  max-width: 100%;
  padding: $xxxl 10%;

  @media screen and (max-width: 1040px) {
    max-width: 90%;
    margin: 0 auto;
    padding: $xxxl 6%;
  }
}
