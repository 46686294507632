.fi {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 28px;

  & .icon-light,
  & .icon-dark {
    position: relative;
    z-index: 1;
  }

  &__amount {
    position: absolute;
    right: -12px;
    bottom: 1px;

    z-index: 2;
    text-align: center;
    background-color: $yellow;
    color: $whiteLight;
    border-radius: $s;
    padding: $zero $s;
    @include applyFontSchema($bodyXSmall, $mulishFamily, $bold);
  }
}
