.snp {
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: $xl;
  margin: 0 0 $l 0;
  cursor: pointer;

  &.column {
    grid-template-columns: repeat(1, auto);
  }
}
