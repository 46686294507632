.form-for-request {
  width: 100%;
  height: auto;

  &.animation {
    animation: fadeIn 1s ease-in-out;
  }

  @media screen and (max-width: 420px) {
    max-width: 90%;
  }

  & .button-icon {
    margin: $sm 0 0 0;
  }
}
