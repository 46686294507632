.tou-toast {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  z-index: 1000;
  flex-direction: row;
  padding: $sm $m;
  border-radius: $m;
  background-color: $whiteLight;
  bottom: $l;
  margin: 0 $l;
  animation: 1.2s fadeInToRight ease-in-out;
  box-shadow: 0px 0px 23px rgba(64, 59, 55, 0.18);

  &.animation-reverse {
    animation: 0.7s fadeInToRightReversed ease-in-out;
  }

  @media screen and (max-width: 560px) {
    flex-direction: column;
    padding: $sm;
    bottom: $m;
    margin: 0 $m;
  }

  & > .button-icon {
    @media screen and (max-width: 860px) {
      padding: $sm $l;
    }
  }

  & > img {
    margin: 0 $s 0 0;

    @media screen and (max-width: 560px) {
      margin: 0 0 $s 0;
    }
  }

  &__label {
    color: $brown;
    margin: 0 $m 0 0;

    text-align: center;
    @include applyFontSchema($bodyLarge, $mulishFamily, $medium);

    @media screen and (max-width: 860px) {
      @include applyFontSchema($bodySmall, $mulishFamily, $medium);
    }

    @media screen and (max-width: 560px) {
      margin: 0 0 $m 0;
    }
  }

  &__link {
    color: $info;
    text-decoration: underline;
    @include applyFontSchema($bodyLarge, $mulishFamily, $medium);
    cursor: pointer;

    @media screen and (max-width: 860px) {
      @include applyFontSchema($bodySmall, $mulishFamily, $medium);
    }
  }
}
