.pre-footer {
  overflow-x: hidden;
  width: 100%;
  background-color: $brown;

  &__layout {
    display: flex;
    flex-direction: row;
    max-width: 80%;
    margin: 0 auto;
    padding: $xxxl 0;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1460px) {
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (max-width: 550px) {
      max-width: 95%;
    }

    @media screen and (max-width: 370px) {
      padding: $sm $sm $l $sm;
    }

    & .signature {
      width: 439.69px;
      height: 182.91px;
      margin: 0;

      @media screen and (max-width: 1460px) {
        margin: 0 0 $l 0;
      }

      @media screen and (max-width: 470px) {
        max-width: 90%;
        margin: 0 auto $l auto;
      }

      @media screen and (max-width: 370px) {
        margin: 0;
      }
    }
  }

  &__links {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 1440px) {
      justify-content: space-around;
    }

    @media screen and (max-width: 710px) {
      flex-direction: column;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    margin: 0 $xxl 0 0;

    @media screen and (max-width: 710px) {
      flex-direction: column;
      align-items: center;
      margin: 0 0 $m 0;
    }

    &:nth-child(2) {
      @media screen and (max-width: 490px) {
        margin: 0 0 $xl 0;
      }
    }

    & > a,
    & > span {
      cursor: pointer;
      margin: 0 0 $xl 0;
      color: $whiteLight;
      @include applyFontSchema($titleMedium, $mulishFamily, $medium);

      @media screen and (max-width: 710px) {
        margin: 0 0 $m 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__contacts {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    align-content: center;
    flex-direction: column;

    @media screen and (max-width: 710px) {
      align-items: center;
    }
  }

  &__contact {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    flex-direction: row;
    margin: 0 0 $m 0;

    &:last-child {
      margin: 0;
    }

    & > img,
    & > svg {
      margin: 0 $s 0 0;
    }

    & > a {
      border-bottom: 1px solid $whiteLight;
      color: $whiteLight;
      @include applyFontSchema($titleMedium, $mulishFamily, $medium);
    }
  }
}
