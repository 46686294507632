.toast-enter {
  animation: toast-enter ease 1s;
}

@keyframes toast-enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
