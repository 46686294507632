@keyframes fadeInToTop {
  0%,
  33%,
  67% {
    opacity: 0;
    transform: translateY(200%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
