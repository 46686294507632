.properties {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__favorite {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    margin: calc($navbarHeight + $l - $xxxl) 0 $xxxl 0;
    animation: fadeInToBottom 0.7s ease-in-out;
    @include applyFontSchema($displayMedium, $pfdFamily, $bold);

    @media screen and (max-width: 685px) {
      margin: calc($navbarHeight + $l - $xxxl) 0 $l 0;
      @include applyFontSchema($displaySmall, $pfdFamily, $bold);
    }

    @media screen and (max-width: 500px) {
      @include applyFontSchema($headlineMedium, $pfdFamily, $bold);
    }

    @media screen and (max-width: 350px) {
      @include applyFontSchema($headlineSmall, $pfdFamily, $bold);
    }

    &.no-data {
      margin: calc($navbarHeight + $l - $xxxl) 0 80px 0;

      @media screen and (max-width: 540px) {
        margin: calc($navbarHeight + $l - $xxxl) 0 60px 0;
      }
    }
  }

  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 80px auto;
    width: 100%;

    @media screen and (max-width: 540px) {
      max-width: 90%;
    }

    @media screen and (max-width: 440px) {
      margin: 0 auto 50px auto;
    }

    & > img {
      margin: 0 0 $l 0;
      animation: fadeIn 0.7s ease-in-out;
    }

    & > span {
      margin: 0 0 $xl 0;
      text-align: center;
      width: 100%;
      max-width: 488px;
      color: $brown;
      animation: fadeInToLeft 0.7s ease-in-out;
      @include applyFontSchema($headlineSmall, $mulishFamily, $regular);

      @media screen and (max-width: 440px) {
        margin: 0 0 $m 0;
        @include applyFontSchema($titleMedium, $mulishFamily, $regular);
      }
    }

    & .button-sba {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: $m $xxxc;

      @media screen and (max-width: 480px) {
        padding: 0 $xs;
        @include applyFontSchema($titleSmall, $mulishFamily, $regular);
      }

      &__label {
        color: $brown;
        @include applyFontSchema($labelLarge, $mulishFamily, $bold);
      }
    }
  }
}
