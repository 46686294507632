.switcher {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px $sm;

  & > img {
    margin: 0 $s 0 0;
  }

  & > span {
    margin: 0 $s 0 0;
    @include applyFontSchema($bodySmall, $mulishFamily, $regular);
  }

  &__button {
    width: 36px;
    height: 18px;
    background-color: $error;
    border-radius: $s;
    padding: 3px;
    cursor: pointer;

    &.switch {
      background-color: $success;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__toggle {
    background-color: $whiteLight;
    width: $sm;
    height: $sm;
    border-radius: 1000px;
  }
}
