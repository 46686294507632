.review-video-player {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  & > video {
    cursor: default;
    width: 80%;
    height: 100%;
    aspect-ratio: 9 / 16;
  }
}
