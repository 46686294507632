.search-item {
  margin: 0 0 34px 0;
  width: 100%;

  & > span {
    margin: 0 0 2px 0;
  }

  &__input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    max-width: 315px;
    padding: 0 0 0 8px;
    border-bottom: 1px solid #bf9a6d;

    @media screen and (max-width: 700px) {
      max-width: 100%;
    }

    & > input {
      padding: 9px 8px;
      background: unset;
      @include applyFontSchema($titleSmall, $mulishFamily, $medium);
    }
  }
}
