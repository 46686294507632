@keyframes fadeInToTopForServiceItem {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  20% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
