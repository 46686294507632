.lang-dd {
  min-width: 137px;

  .lang-dd:hover > &__list,
  & .lang-dd,
  &__list:hover {
    display: block;
  }

  &.dark &__current > span {
    color: $brown;
  }

  &__current {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-direction: row;
    cursor: pointer;
    height: $xxl;

    & > span {
      color: $whiteLight;
      margin: 0 $s 0 0;
      @include applyFontSchema($bodySmall, $mulishFamily, $bold);
    }
  }

  &__list {
    min-width: 137px;
    display: none;
    position: absolute;
    z-index: 10;
    padding: $sm;
    border-radius: $s;
    background: $whiteLight;
    box-shadow:
      0px 0px 23px 0px rgba(64, 59, 55, 0.18),
      0px 1px 9px 0px rgba(64, 59, 55, 0.08);
  }

  &__item {
    display: flex;
    align-items: center;
    align-content: center;
    cursor: pointer;
    justify-content: flex-start;
    flex-direction: row;
    margin: 14px 0 0 0;

    &:first-child {
      margin: 0;
    }

    & > span {
      color: $brown;
      margin: 0 $s 0 0;
      @include applyFontSchema($bodySmall, $mulishFamily, $bold);
    }
  }

  &__flag {
    margin: 0 $sm 0 0;
  }
}
