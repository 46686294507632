* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  border: 0;
  border-radius: 0;

  @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

img {
  -webkit-user-drag: none;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: $brownLight;
}

::-webkit-scrollbar-thumb {
  background-color: $brownDark;
}

*:disabled {
  opacity: 0.3;
  pointer-events: none;
}

textarea {
  resize: none;
}

#placement-popper {
  z-index: 1000;
}

.MuiSlider-thumb {
  color: $yellow !important;
  width: 18px !important;
  height: 18px !important;

  &:hover {
    box-shadow: 0px 0px 0px 10px rgb(191, 154, 109, 0.18) !important;
  }
}

.Mui-active {
  box-shadow: 0px 0px 0px 10px rgb(191, 154, 109, 0.18) !important;

  &:hover {
    box-shadow: 0px 0px 0px 10px rgb(191, 154, 109, 0.18) !important;
  }
}

.MuiSlider-rail {
  background-color: $white !important;
}

.MuiSlider-track {
  background-color: $yellow !important;
  border-color: $yellow !important;
}

.MuiSlider-root {
  padding: 10px 0 !important;
}
