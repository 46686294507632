// FONTS FAMILIES

$mulishFamily: 'Mulish';
$pfdFamily: 'PlayfairDisplay';

// FONTS WEIGHTS

$regular: 'Regular';
$medium: 'Medium';
$bold: 'Bold';

// FONTS SCHEME

$displayLarge: 'Display Large';
$displayMedium: 'Display Medium';
$displaySmall: 'Display Small';

$headlineLarge: 'Headline Large';
$headlineMedium: 'Headline Medium';
$headlineSmall: 'Headline Small';

$titleLarge: 'Title Large';
$titleMedium: 'Title Medium';
$titleSmall: 'Title Small';

$labelLarge: 'Label Large';
$labelMedium: 'Label Medium';
$labelSmall: 'Label Small';

$bodyLarge: 'Body Large';
$bodyMedium: 'Body Medium';
$bodySmall: 'Body Small';
$bodyXSmall: 'Body XSmall';

// COLORS SCHEME

$whiteLight: #f3f3f3;
$white: #e0dbd5;
$brownLight: #a09b97;
$brown: #403b37;
$brownFortyPerCent: #403b3766;
$brownDark: #312c28;
$black: #131312;
$yellow: #bf9a6d;
$spaceWhite: #c6c6c6;
$maline: #cd4e74;
$error: #cc5d5d;
$warning: #ccad5d;
$success: #55a066;
$info: #5d97cc;

// SPACING

$zero: 0;
$one: 1px;
$xxs: 2px;
$xs: 4px;
$s: 8px;
$sm: 12px;
$m: 16px;
$l: 24px;
$xl: 32px;
$xxl: 40px;
$xxxl: 48px;
$c: 56px;
$xc: 64px;
$xxc: 72px;
$xxxc: 80px;

// EXTRA

$navbarHeight: 87px;

// BUTTONS THEME

.default-filled-button {
  background-color: $yellow;
  border-radius: $xxxc;
  padding: $sm $c;
  color: $whiteLight;

  &.animation {
    animation: fadeInToLeft 0.7s ease-in-out;
  }

  &.disabled {
    opacity: 0.6;
  }
}

.default-outline-button {
  background-color: transparent;
  border-radius: $xxxc;
  padding: $sm;
  color: $brownLight;
  width: 100%;
  border: $one solid $brownLight;
  transition: all 0.5s ease-in-out;

  & > span {
    color: $brownLight;
  }

  &:hover {
    background-color: $brownLight;

    & > span {
      color: $whiteLight;
    }

    & > svg {
      & path {
        stroke: $whiteLight;
      }
    }
  }
}

.default-outline-button-without-border {
  background-color: transparent;
  border-radius: $xxxc;
  padding: $sm;
  color: $yellow;
  width: 100%;
}

// ICONS

.icon-light {
  & path {
    stroke: $whiteLight;
  }

  & rect {
    fill: $whiteLight;
  }
}

.icon-dark {
  & path {
    stroke: $brown;
  }

  & rect {
    fill: $brown;
  }
}
