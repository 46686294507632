.who-is-nr {
  width: 100%;
  display: flex;
  margin: 127px 0 0 0;
  align-items: center;
  overflow: hidden;
  justify-content: center;

  &__content {
    padding: 106px 220px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;

    @media screen and (max-width: 1700px) {
      padding: 106px 110px;
    }

    @media screen and (max-width: 1580px) {
      padding: 106px 65px;
    }

    @media screen and (max-width: 1410px) {
      padding: 106px 32px;
    }

    @media screen and (max-width: 1350px) {
      flex-direction: column;
      padding: 39px 32px;
    }
  }

  &__photo {
    position: relative;
    z-index: 1;
    margin: 0 $xc 0 0;

    @media screen and (max-width: 1410px) {
      margin: 0 $m 0 0;
    }

    @media screen and (max-width: 1350px) {
      margin: 0;
    }

    &.animation {
      animation: fadeInToRight 0.4s ease-in-out;
    }

    & .signature {
      position: absolute;
      z-index: 1;
      width: 421px;
      height: 118px;
      top: -50px;
      left: -80px;
      rotate: (-8.44deg);

      @media screen and (max-width: 1580px) {
        left: -25px;
      }

      @media screen and (max-width: 1350px) {
        left: -80px;
      }

      @media screen and (max-width: 840px) {
        left: -40px;
      }

      @media screen and (max-width: 750px) {
        max-width: 80%;
        left: -20px;
      }

      @media screen and (max-width: 410px) {
        max-width: 80%;
        left: -10px;
      }
    }

    & > img {
      object-fit: contain;

      @media screen and (max-width: 780px) {
        width: 100%;
      }
    }
  }

  &__description {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;

    &.animation {
      animation: fadeInToLeft 0.4s ease-in-out;
    }

    @media screen and (max-width: 1350px) {
      align-items: center;
      margin: 0 0 $xc 0;
    }

    & > h1 {
      margin: 0 0 $xl 0;
      @include applyFontSchema($displaySmall, $pfdFamily, $regular);
    }

    & > p {
      margin: 0 0 $m 0;
      width: 100%;
      max-width: 728px;
      @include applyFontSchema($titleMedium, $mulishFamily, $medium);

      @media screen and (max-width: 1350px) {
        max-width: 95%;
      }
    }
  }
}

.why-is-nr {
  background-color: $brown;
  width: 100%;
  display: flex;
  padding: $xxxl 220px;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin: 0 0 -1px 0;

  @media screen and (max-width: 1880px) {
    padding: $xxxl 110px;
  }

  @media screen and (max-width: 1680px) {
    padding: $xxxl 60px;
  }

  @media screen and (max-width: 1430px) {
    padding: $xxxl 50px;
  }

  @media screen and (max-width: 650px) {
    padding: $xxxl 20px;
  }

  &__content {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 1350px) {
      flex-direction: column;
    }
  }

  &__photo {
    &.animation {
      animation: fadeInToLeft 0.4s ease-in-out;
    }

    @media screen and (max-width: 740px) {
      width: 100%;
      object-fit: contain;
    }
  }

  &__description {
    width: 100%;
    height: 100%;
    max-height: 75%;
    margin: 0 66px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    color: $whiteLight;

    &.animation {
      animation: fadeInToRight 0.4s ease-in-out;
    }

    @media screen and (max-width: 1580px) {
      margin: 0 $xl 0 0;
    }

    @media screen and (max-width: 1350px) {
      align-items: center;
      margin: 0 0 $xl 0;
    }

    & > h1 {
      margin: 0 0 $xl 0;
      @include applyFontSchema($displaySmall, $pfdFamily, $regular);
    }

    & .achievements {
      padding: 0;

      @media screen and (max-width: 1900px) {
        max-width: 100%;
      }

      @media screen and (max-width: 1450px) {
        margin: 0 0 $l 0;
      }

      @media screen and (max-width: 1350px) {
        max-width: 60%;
      }

      @media screen and (max-width: 1000px) {
        max-width: 100%;
      }
    }
  }

  &__body {
    margin: 0 0 $l 0;

    @media screen and (max-width: 1350px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-content: center;
    }

    & > p {
      width: 100%;
      max-width: 728px;
      margin: 0 0 $m 0;
      @include applyFontSchema($titleMedium, $mulishFamily, $medium);

      @media screen and (max-width: 1350px) {
        max-width: 90%;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__license {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid $yellow;
    padding: $sm $l;
    margin: 0 0 $xxl 0;
    border-radius: 100px;

    @media screen and (max-width: 1350px) {
      display: none;
    }

    &.mobile {
      display: none;

      @media screen and (max-width: 1350px) {
        display: flex;
        margin: $xxl 0 0 0;
      }
    }

    & > span {
      color: $whiteLight;
      margin: 0 $s 0 0;
      @include applyFontSchema($titleMedium, $mulishFamily, $medium);
    }

    & > h2 {
      color: $whiteLight;
      @include applyFontSchema($titleMedium, $mulishFamily, $bold);
    }
  }

  &__advantages {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > hr {
      width: 100%;
      object-fit: contain;
      border-top: 1px solid $brownLight;
      margin: 0 0 $l 0;

      @media screen and (max-width: 1350px) {
        max-width: 63%;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    @media screen and (max-width: 1350px) {
      max-width: 90%;
      justify-content: center;
    }

    @media screen and (max-width: 900px) {
      max-width: 100%;
    }

    @media screen and (max-width: 400px) {
      max-width: 100%;
    }

    &:last-child {
      margin: 0;
    }

    & > hr {
      width: 100%;
      border-top: 1px solid $brownLight;

      @media screen and (max-width: 1350px) {
        max-width: 70%;
      }
    }
  }

  &__advantage-layout {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding: 23px 16px;

    @media screen and (max-width: 1350px) {
      justify-content: center;
    }

    @media screen and (max-width: 550px) {
      flex-direction: column;
      text-align: center;
      padding: $sm 16px;
    }

    & > h3 {
      color: $yellow;
      margin: 0 $xl 0 0;
      @include applyFontSchema($headlineMedium, $mulishFamily, $regular);

      @media screen and (max-width: 550px) {
        margin: 0;
      }
    }
  }

  &__advantage-title {
    width: 100%;
    margin: 0 $xl 0 0;
    max-width: 138px;
    @include applyFontSchema($titleMedium, $mulishFamily, $bold);

    @media screen and (max-width: 550px) {
      margin: 0 0 $s 0;
    }
  }

  &__advantage-description {
    width: 100%;
    max-width: 400px;
    @include applyFontSchema($titleSmall, $mulishFamily, $medium);
  }
}
