.tou {
  width: 100%;
  background-color: $whiteLight;
  border-radius: $m;
  padding: $xxl $xxxl;
  max-width: 80%;
  margin: 120px auto;
  text-align: left;

  @media screen and (max-width: 1345px) {
    max-width: 90%;
  }

  @media screen and (max-width: 500px) {
    padding: $l;
  }

  & > h1 {
    width: 100%;
    text-align: center;
    margin: 0 0 $m 0;
    color: $black;
    word-break: break-all;
    @include applyFontSchema($headlineLarge, $mulishFamily, $bold);

    @media screen and (max-width: 1180px) {
      font-size: 28px;
    }

    &::before {
      content: ' ';
      width: 20%;
      height: 1px;
      display: inline-block;
      margin: 0 $l 9px 0;
      background-color: $brownLight;

      @media screen and (max-width: 1180px) {
        display: flex;
        margin: 0 auto 8px auto;
      }
    }

    &::after {
      content: ' ';
      width: 20%;
      height: 1px;
      display: inline-block;
      margin: 0 0 9px $l;
      background-color: $brownLight;

      @media screen and (max-width: 1180px) {
        display: none;
      }
    }
  }

  & > img {
    margin: 0 auto $m auto;
    display: block;
  }

  & > p {
    margin: 0 0 $m 0;
    @include applyFontSchema($bodyLarge, $mulishFamily, $regular);

    &:last-child {
      margin: 0;
    }

    & > b {
      @include applyFontSchema($bodyLarge, $mulishFamily, $bold);
    }
  }

  & > h3 {
    margin: $s 0 $sm 0;
    color: $black;
    word-break: break-all;
    @include applyFontSchema($headlineSmall, $pfdFamily, $bold);
  }

  & > ul {
    padding: 0 $l;
    margin: 0 0 $m 0;

    & > li {
      list-style-type: disc;
      color: $black;
      margin: 0 0 $xxs 0;
      @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
    }
  }
}
