.property-area {
  background-color: $whiteLight;
  padding: 112px 0 42px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;

  & > hr {
    width: 100%;
    border-top: 1px solid $white;

    &.header-top {
      margin: 16px 0px 0px 0px;
    }

    &.header-bottom {
      margin: 0px 0px 24px 0px;
    }
  }

  &__header {
    max-height: 700px;
    width: 100%;
    margin: 0 auto;
    position: relative;

    & > img {
      min-height: 600px;
      max-height: 600px;
      border-radius: $m;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      object-fit: cover;

      @media screen and (max-width: 960px) {
        max-width: 80%;
        width: 100%;
      }
    }
  }

  &__slider {
    margin: 0 0 $m 0;

    & div {
      min-height: 600px;
      max-height: 600px;
      border-radius: $m;

      @media screen and (min-width: 900px) {
        display: flex;
        flex-direction: row;
      }

      @media screen and (max-width: 900px) {
        border-radius: 0;
        // width: 100vw;
        // height: 100%;
        min-height: unset;
        max-height: unset;
      }
    }

    & img {
      width: fit-content !important;
      margin: 0 auto;
      min-height: 600px;
      max-height: 600px;
      object-fit: cover;
      border-radius: $m;
      opacity: 0.3;

      @media screen and (max-width: 900px) {
        border-radius: 0;
        width: 100vw !important;
        object-fit: contain;
        min-height: auto;
        max-height: unset;
      }
    }

    .slick-slide > div {
      padding: 0 $m;
    }

    & .slick-center {
      & div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      & img {
        width: 100%;
        height: 100%;
        opacity: 1 !important;
      }
    }

    &-navigation {
      max-width: 80%;
      max-height: 84px;
      min-height: 84px;
      margin: 0 auto;

      .slick-slide > div {
        padding: 0 $s;
        max-height: 84px;
        min-height: 84px;
      }

      & img {
        max-height: 84px;
        min-height: 84px;
        object-fit: fill;
        border-radius: $s;
      }
    }
  }

  &__header-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 1150px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    & > span {
      color: $yellow;
      margin: 0 0 4px 0;
      text-transform: uppercase;
      @include applyFontSchema($bodyLarge, $mulishFamily, $bold);

      @media screen and (max-width: 410px) {
        margin: 0;
      }
    }
  }

  &__description {
    margin: $l 0;

    p {
      color: $brown;
      margin: 0 0 $m 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__info-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1150px) {
      margin: 0 0 $m 0;
    }

    @media screen and (max-width: 780px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      margin: 0 0 $s 0;
    }

    @media screen and (max-width: 410px) {
      flex-direction: column;
      margin: 0 0 $s 0;
    }

    & > h1 {
      width: 100%;
      max-width: 90%;
      margin: 0 22px 0 0;
      @include applyFontSchema($headlineSmall, $pfdFamily, $bold);

      @media screen and (max-width: 780px) {
        text-align: center;
        margin: 0;
        max-width: unset;
        width: unset;
      }

      @media screen and (max-width: 410px) {
        margin: 0 0 $s 0;
      }
    }

    & > span {
      padding: 6px 28px;
      border: 1px solid $brownLight;
      border-radius: 19px;
      white-space: nowrap;
      @include applyFontSchema($titleSmall, $mulishFamily, $medium);
    }
  }

  &__features {
    margin: $sm 0 0 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;

    & > h2 {
      color: $brown;
      margin: 0 0 $l 0;
      @include applyFontSchema($titleLarge, $mulishFamily, $bold);
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 2fr);
      align-items: center;
      row-gap: $sm;
      column-gap: 70px;
      padding: 0 125px 0 0;
      width: 100%;
      // max-width: 788px;
      margin: 0 0 $xl 0;
      justify-items: start;
      justify-content: space-between;
      align-content: center;

      @media screen and (max-width: 1300px) {
        padding: 0;
      }

      @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 2fr);
      }

      @media screen and (max-width: 900px) {
        grid-template-columns: repeat(3, 2fr);
      }

      @media screen and (max-width: 800px) {
        grid-template-columns: repeat(2, 2fr);
      }

      @media screen and (max-width: 500px) {
        grid-template-columns: repeat(1, 2fr);
      }

      &:last-child {
        margin: 0;

        @media screen and (max-width: 900px) {
          margin: 0 0 $xl 0;
        }
      }
    }
  }

  &__title {
    width: 100%;
    padding: 20px 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1400px) {
      padding: 20px 70px;
    }

    @media screen and (max-width: 1150px) {
      flex-direction: column;
      padding: 20px;
    }
  }

  &__cost {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: row;

    & > span {
      color: $yellow;
      margin: 0 26px 0 0;
      white-space: nowrap;
      @include applyFontSchema($headlineSmall, $mulishFamily, $bold);
    }

    & .button-favorite {
      width: 32px;
      height: 32px;
      position: unset;
    }
  }

  &__energy-grade {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    border: 2px solid black;
    margin: 0 0 16px 0;

    & > span {
      text-transform: uppercase;
    }

    &.a_three_plus {
      border-color: #00964e;
    }

    &.a_two_plus {
      border-color: #00aa01;
    }

    &.a_one_plus {
      border-color: #a9c801;
    }

    &.a {
      border-color: #ffd644;
    }

    &.b {
      border-color: #f0c140;
    }

    &.c {
      border-color: #ff7d01;
    }

    &.d {
      border-color: #fe0000;
    }

    &.e {
      border-color: #e10000;
    }

    &.f {
      border-color: #c80000;
    }

    &.g {
      border-color: #ae0001;
    }
  }

  &__details {
    width: 100%;
    padding: 0 220px 32px 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1700px) {
      padding: 32px 40px;
    }

    @media screen and (max-width: 900px) {
      padding: $s 40px;
      flex-direction: column;
    }

    &__description {
      width: 100%;
      height: 100%;
      padding: 0 96px 0 0;
      margin: 0 48px 0 0;
      border-right: 1px solid $brownLight;

      @media screen and (max-width: 1340px) {
        margin: 0 20px 0 0;
        padding: 0 20px 0 0;
      }

      @media screen and (max-width: 900px) {
        border: none;
        margin: 0;
        padding: 0;
      }

      @media screen and (max-width: 520px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      & > h1 {
        margin: 0 0 12px 0;
        @include applyFontSchema($headlineSmall, $pfdFamily, $bold);
      }

      & > span {
        width: 100%;
        color: $brown;
        margin: 0 0 32px 0;
        @include applyFontSchema($titleMedium, $mulishFamily, $medium);
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      max-width: 30%;

      @media screen and (max-width: 900px) {
        max-width: 100%;
      }

      & .button-icon {
        width: 60%;
        margin: 0 0 24px 0;

        @media screen and (max-width: 1040px) {
          width: 80%;
        }
      }

      & > hr {
        width: 100%;
        max-width: 470px;
        margin: 0 0 24px 0;
        border-top: 1px solid $brownLight;
      }

      &__grade {
        width: 100%;
        max-width: 394px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: center;

        & > h2 {
          margin: 0 0 16px 0;
          @include applyFontSchema($headlineSmall, $pfdFamily, $medium);
        }
      }

      &__grid {
        width: 100%;
        max-width: 394px;
        margin: 0 0 24px 0;
        display: grid;
        grid-template-columns: repeat(2, auto);
        column-gap: 36px;
        row-gap: 18px;
        justify-items: center;
        justify-content: start;
        align-items: center;
        align-content: center;

        @media screen and (max-width: 1640px) {
          column-gap: 18px;
          row-gap: 18px;
        }

        @media screen and (max-width: 1300px) {
          grid-template-columns: repeat(1, auto);
          justify-content: center;
        }

        @media screen and (max-width: 900px) {
          width: 100%;
          justify-content: center;
          grid-template-columns: repeat(2, auto);
        }

        @media screen and (max-width: 420px) {
          width: 100%;
          grid-template-columns: repeat(1, auto);
        }

        & .label-icon {
          width: 100%;
          justify-content: flex-start;
        }
      }

      & .button-sba__label {
        color: $brown;
        @include applyFontSchema($titleSmall, $mulishFamily, $bold);

        @media screen and (max-width: 400px) {
          padding: 16px 34px;
          @include applyFontSchema($bodyXSmall, $mulishFamily, $bold);
        }
      }
    }
  }

  &__controllers {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 2fr);
    column-gap: 24px;
    margin: 0 0 12px 0;

    @media screen and (max-width: 1150px) {
      grid-template-columns: repeat(2, auto);
    }

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(4, 2fr);
      column-gap: $m;
    }

    @media screen and (max-width: 760px) {
      grid-template-columns: repeat(2, 2fr);
    }

    @media screen and (max-width: 680px) {
      width: 100%;
      column-gap: $s;
    }

    @media screen and (max-width: 450px) {
      max-width: 80%;
      grid-template-columns: repeat(1, auto);
    }

    @media screen and (max-width: 420px) {
      max-width: 90%;
    }

    & .button-icon {
      height: 100%;
      max-height: 44px;

      &:nth-child(3),
      :nth-child(4) {
        @media screen and (max-width: 1150px) {
          margin: 0;
        }
      }

      @media screen and (max-width: 1150px) {
        margin: 0 0 $s 0;
      }

      @media screen and (max-width: 520px) {
        margin: 0 auto $s auto;
      }

      &:nth-child(3) {
        @media screen and (max-width: 420px) {
          margin: 0 0 $s 0;
        }
      }
    }
  }

  &__info-button {
    width: 32px;
    height: 32px;
    margin: 0 16px 0 0;
    cursor: pointer;
    position: relative;
    z-index: 5;

    // @media screen and (max-width: 1150px) {
    //   position: relative;
    // }
  }

  .property-area__info-button:hover > &__info,
  & .property-area__info-button,
  &__info:hover {
    display: block;
  }

  &__popup {
    padding: $m;
    position: relative;
    z-index: 10;
    background: $whiteLight;
    border-radius: $l;

    & b {
      display: block;
      margin: $m 0;
      color: $brown;
      @include applyFontSchema($bodyLarge, $mulishFamily, $bold);

      &:first-child {
        margin: 0 0 $m 0;
      }
    }

    & ul {
      margin: 0 0 0 $l;

      & li {
        color: $brown;
        list-style-type: '●';
        padding: 0 0 $xxs $s;
      }
    }
  }

  &__info {
    width: 100%;
    display: none;

    &__details {
      position: absolute;
      z-index: 6;
      display: flex;
      flex-direction: column;
      min-width: 529px;
      max-width: 529px;
      border-radius: $l;
      box-shadow: 0px 0px 23px rgb(64, 59, 55, 0.18);
      background-color: $whiteLight;
      right: -55px;
      margin: $sm 0 0 0;

      @media screen and (max-width: 880px) {
        right: -192px;
      }

      @media screen and (max-width: 580px) {
        min-width: 300px;
        max-width: 300px;
        right: -85px;
      }

      &__sub-title {
        @include applyFontSchema($bodyLarge, $mulishFamily, $bold);
      }

      &__title {
        @include applyFontSchema($bodyLarge, $mulishFamily, $regular);
      }
    }

    .triangle {
      position: absolute;
      z-index: 1;
      top: -9px;
      right: 60px;
      rotate: 225deg;
      width: 0;
      height: 0;
      border-top-width: 18px;
      border-top-style: solid;
      border-top-color: transparent;
      border-right-width: 18px;
      border-right-style: solid;
      border-right-color: $whiteLight;
      box-shadow: 0px 0px 23px rgb(64, 59, 55, 0.18);

      @media screen and (max-width: 880px) {
        right: 197px;
      }

      @media screen and (max-width: 580px) {
        right: 90px;
      }
    }
  }

  &__controller {
    position: absolute;
    height: 100%;
    max-height: calc(100% - 100px);
    width: $c;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.left {
      left: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.442602) -5.92%,
        rgba(0, 0, 0, 0) 80.26%
      );
    }

    &.right {
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        270deg,
        rgba(0, 0, 0, 0.442602) -5.92%,
        rgba(0, 0, 0, 0) 80.26%
      );
    }
  }

  &__image-highlight {
    width: 100%;
    height: calc(100% - 100px);
    position: absolute;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    & > h4 {
      width: 100%;
      background-color: #00000099;
      display: block;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $whiteLight;
      padding: $xxl 0;
      @include applyFontSchema($displaySmall, $mulishFamily, $bold);
    }
  }
}
