.categories-controller {
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: $s;

  &.animation {
    animation: fadeInToLeft 1s ease-in-out;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(2, auto);
    row-gap: $s;
    margin: 0 0 $m 0;
  }

  @media screen and (max-width: 420px) {
    flex-direction: column;
  }

  & > span {
    padding: $s $m;
    text-align: center;
    cursor: pointer;
    @include applyFontSchema($titleMedium, $mulishFamily, $medium);

    &.active {
      background-color: $yellow;
      border-radius: $xl;
      color: $whiteLight;
    }
  }
}
