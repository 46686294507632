.news {
  width: 100%;
  height: 100vh;
  max-height: 482px;
  overflow: hidden;
  animation: fadeIn 0.7s ease-in-out;

  &__bg {
    width: 100%;
    height: 100%;
    max-height: 482px;
    position: absolute;
    z-index: 0;

    & > img {
      width: 100%;
      height: 100%;
      max-height: 482px;
      object-fit: cover;
    }
  }

  &__fg {
    width: 100%;
    height: 100%;
    max-height: 482px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;

    & > h1 {
      color: $whiteLight;
      margin: 0 0 $s 0;
      @include applyFontSchema($displaySmall, $pfdFamily, $bold);
      font-size: 52px;
    }
  }
}

.news-content {
  width: 100%;
  background-color: $whiteLight;
  width: 100%;
  min-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 346px);
  margin: 0 auto;
  padding: $xxl 10%;
  row-gap: $l;
  column-gap: $xl;

  @media screen and (max-width: 1820px) {
    grid-template-columns: repeat(3, 346px);
  }

  @media screen and (max-width: 1370px) {
    grid-template-columns: repeat(2, 346px);
  }

  @media screen and (max-width: 1040px) {
    max-width: 90%;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, auto);
  }

  & > .article {
    transform: unset;
    rotate: unset;
    display: flex !important;
    margin: 0 auto !important;
  }
}
