.review {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  background-color: $whiteLight;
  padding: $l;
  border-radius: $m;

  @media screen and (max-width: 1530px) {
    min-width: 280px;
  }

  @media screen and (max-width: 1200px) {
    margin: 0 0 $l 0;
    min-width: 350px;
  }

  @media screen and (max-width: 370px) {
    min-width: 270px;
    padding: $m;
  }

  &.animation {
    &:nth-child(1) {
      animation: fadeInToRight 0.5s ease-in-out;
    }

    &:nth-child(2) {
      animation: fadeInToRight 1s ease-in-out;
    }

    &:nth-child(3) {
      animation: fadeInToRight 1.5s ease-in-out;
    }

    &:nth-child(4) {
      animation: fadeInToRight 2s ease-in-out;
    }
  }

  &__video {
    width: 98px;
    height: 98px;
    min-width: 98px;
    min-height: 98px;
    max-width: 98px;
    max-height: 98px;
    position: relative;
    margin: 0 $xl 0 0;
    z-index: 4;
    cursor: pointer;

    & > img {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      max-width: 24px;
      max-height: 24px;
      position: absolute;
      z-index: 5;
      bottom: 4px;
      left: 37px;
    }

    & > video {
      position: relative;
      z-index: 4;
      width: 98px;
      height: 98px;
      min-width: 98px;
      min-height: 98px;
      max-width: 98px;
      max-height: 98px;
      border-radius: 50%;
      margin: 0 $xl 0 0;
      object-fit: cover;
      object-position: top;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-top: 1px solid $spaceWhite;
    padding: $l 0 0 0;
    width: 100%;
    height: 100%;
    max-height: 122px;
    min-height: 122px;

    @media screen and (max-width: 500px) {
      align-items: center;
    }

    & > img {
      width: 98px;
      height: 98px;
      min-width: 98px;
      min-height: 98px;
      max-width: 98px;
      max-height: 98px;
      border-radius: 50%;
      margin: 0 $l 0 0;
      object-fit: cover;
    }
  }

  &__client-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__from {
    max-width: 113px;
    @include applyFontSchema($titleMedium, $mulishFamily, $bold);
  }

  &__description {
    max-width: 302px;
    padding: 0 0 24px 0;
    @include applyFontSchema($labelLarge, $mulishFamily, $medium);
  }

  &__where {
    @include applyFontSchema($titleSmall, $mulishFamily, $medium);
  }
}
