@keyframes fadeInToRightSlow {
  0% {
    opacity: 0;
    transform: translateX(-30%);
  }

  33%,
  67% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
