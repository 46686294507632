.achievements {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 0 0 100px;
  margin: 0 0 $c 0;
  max-width: 80%;
  justify-content: flex-start;
  align-items: center;

  &.animation {
    animation: fadeInToRight 0.7s ease-in-out;
  }

  @media screen and (max-width: 1520px) {
    padding: 0;
    max-width: 100%;
  }

  @media screen and (max-width: 1320px) {
    max-width: 95%;
  }

  @media screen and (max-width: 1220px) {
    position: unset;
    max-width: 90%;
    justify-content: center;
    margin: 0 auto $xxl auto;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }

  hr {
    width: 30%;
    border-top: 1px solid $yellow;

    @media screen and (max-width: 1320px) {
      margin: 0 $m;
    }

    @media screen and (max-width: 550px) {
      margin: $m 0 $m 0;
      width: 20%;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 $l 0 0;

    &:last-child {
      margin: 0;
    }

    @media screen and (max-width: 550px) {
      margin: 0;
    }
  }

  &__stats {
    margin: 0 0 $sm 0;
    color: $whiteLight;
    @include applyFontSchema($displayMedium, $mulishFamily, $regular);
  }

  &__label {
    color: $white;
    text-align: center;
    @include applyFontSchema($bodyMedium, $mulishFamily, $regular);
  }
}
