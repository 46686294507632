.form-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0 $sm 0;

  & > span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 0 $xxs 0;

    @include applyFontSchema($bodySmall, $mulishFamily, $regular);
  }

  & > input,
  & > textarea {
    width: 100%;
    height: 100%;
    padding: $s;
    background-color: $whiteLight;
    border-bottom: 1px solid $yellow;
    @include applyFontSchema($labelLarge, $mulishFamily, $medium);

    &::placeholder {
      color: $brownLight;
    }

    &:focus {
      border-color: $yellow;
    }
  }
}
