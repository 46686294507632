.button-sba {
  position: relative;
  z-index: 0;
  cursor: pointer;

  animation: 1.5s fadeInToRight ease-out;

  &__animation {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  &__label {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $white;
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    @include applyFontSchema($labelLarge, $mulishFamily, $bold);

    @media screen and (max-width: 580px) {
      padding: $m $xc;
    }
  }
}
