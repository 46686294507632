footer {
  width: 100%;
  overflow: hidden;
  background-color: $brownDark;

  & .footer {
    &__layout {
      max-width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      flex-direction: row;
      padding: $l 0;
      color: $whiteLight;

      @media screen and (max-width: 640px) {
        flex-direction: column;
      }

      @media screen and (max-width: 380px) {
        max-width: 100%;
      }

      @media screen and (max-width: 380px) {
        padding: $m 0;
      }

      & > span {
        @include applyFontSchema($titleMedium, $mulishFamily, $medium);

        @media screen and (max-width: 640px) {
          flex-direction: column;
          margin: 0 0 $sm 0;
        }

        @media screen and (max-width: 380px) {
          @include applyFontSchema($titleSmall, $mulishFamily, $regular);
        }
      }
    }

    &__developers {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      flex-direction: row;
      cursor: pointer;

      & > span {
        margin: 0 $m 0 0;
        text-transform: uppercase;
        @include applyFontSchema($titleMedium, $mulishFamily, $medium);

        @media screen and (max-width: 380px) {
          @include applyFontSchema($titleSmall, $mulishFamily, $regular);
        }
      }
    }
  }
}
