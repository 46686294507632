.ask-for-viewing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: rgba(64, 59, 55, 0.5);
  backdrop-filter: blur(5px);

  &__container {
    padding: $l;
    opacity: 100%;
    background-color: $whiteLight;
    border-radius: $m;
    width: 100%;
    max-width: 396px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    @media screen and (max-width: 460px) {
      max-width: 90%;
      margin: 0 auto;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 $m 0;
    position: relative;

    & > img {
      cursor: pointer;
      position: absolute;
      right: 0;
    }

    & > span {
      margin: 0 auto;
      color: $brown;
      @include applyFontSchema($titleSmall, $mulishFamily, $medium);
    }
  }
}
