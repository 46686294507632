.tou-agreement {
  color: $brown;
  cursor: pointer;
  position: relative;
  text-indent: 26px;
  @include applyFontSchema($labelLarge, $mulishFamily, $medium);
  user-select: none;

  &.disabled {
    opacity: 0.7;
  }

  & > svg {
    position: absolute;
    left: -2px;
    top: -3px;

    &.disabled {
      opacity: 0.7;
    }
  }

  &__link {
    color: $info;
    text-decoration: underline;
    cursor: pointer;

    @include applyFontSchema($labelLarge, $mulishFamily, $medium);
  }
}
