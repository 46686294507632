.search-pane {
  width: 100%;
  height: 87px;
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 0;
  left: 0;
  background: $whiteLight;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
  box-shadow: 0px 0px 23px rgba(64, 59, 55, 0.18);

  @media screen and (max-width: 1120px) {
    justify-content: space-between;
    padding: 0 0 0 1%;
  }

  @media screen and (max-width: 480px) {
    padding: 0;
  }

  &__filters {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  &__controllers {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1120px) {
      min-width: $xxc;
      max-width: $xxc;
      width: 100%;
      flex-direction: column;
    }

    @media screen and (max-width: 340px) {
      min-width: $c;
      max-width: $c;
    }
  }

  &__hitbox {
    width: fit-content;
    height: 100%;
    cursor: pointer;
    user-select: none;
    padding: 0 $sm;
    background-color: $whiteLight;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1120px) {
      padding: 0 $xs;
      width: 100%;
    }

    &:first-child {
      @media screen and (max-width: 1120px) {
        display: none;
      }
    }

    &:nth-child(3) {
      @media screen and (max-width: 1120px) {
        background-color: $white;
      }
    }

    &.mobile {
      display: none;

      @media screen and (max-width: 1120px) {
        display: flex;
        margin: 0 0 0 $sm;
      }

      @media screen and (max-width: 1000px) {
        margin: 0;
      }

      @media screen and (max-width: 480px) {
        width: min-content;
      }
    }

    &.primary {
      background-color: $yellow;
      padding: 0 $l;

      @media screen and (max-width: 1120px) {
        padding: 0 $xs;
      }

      & img {
        margin: 0 $s 0 0;

        @media screen and (max-width: 1120px) {
          margin: 0;
          width: 24px;
          height: 24px;
        }

        &:first-child {
          @media screen and (max-width: 480px) {
            display: none;
          }
        }

        &.mobile {
          display: none;

          @media screen and (max-width: 480px) {
            display: block;
          }
        }
      }

      & span {
        color: $whiteLight;
        text-transform: uppercase;
        @include applyFontSchema($labelLarge, $mulishFamily, $bold);

        @media screen and (max-width: 1120px) {
          display: none;
        }
      }
    }
  }

  &__range {
    width: min-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin: 0 $m 0 0;

    & > img {
      display: none;
      width: 24px;
      height: 24px;
    }

    &:nth-child(1) {
      @media screen and (max-width: 650px) {
        margin: 0;
      }

      @media screen and (max-width: 480px) {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        display: none;
      }

      & > span {
        @media screen and (max-width: 480px) {
          display: none;
        }
      }

      & > .search-pane__free-search {
        @media screen and (max-width: 480px) {
          display: none;
        }
      }

      & > img {
        @media screen and (max-width: 480px) {
          display: block;
        }
      }
    }

    &:nth-child(2) {
      @media screen and (max-width: 830px) {
        margin: 0;
      }

      @media screen and (max-width: 650px) {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
      }

      & > span {
        @media screen and (max-width: 650px) {
          display: none;
        }
      }

      & > .search-pane__range-selector {
        @media screen and (max-width: 650px) {
          display: none;
        }
      }

      & > img {
        @media screen and (max-width: 650px) {
          display: block;
        }
      }
    }

    &:nth-child(3) {
      @media screen and (max-width: 900px) {
        margin: 0;
      }

      @media screen and (max-width: 830px) {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
      }

      & > span {
        @media screen and (max-width: 830px) {
          display: none;
        }
      }

      & > .search-pane__range-selector {
        @media screen and (max-width: 830px) {
          display: none;
        }
      }

      & > img {
        @media screen and (max-width: 830px) {
          display: block;
        }
      }
    }

    &:nth-child(4) {
      @media screen and (max-width: 1000px) {
        margin: 0;
      }

      @media screen and (max-width: 900px) {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
      }

      & > span {
        @media screen and (max-width: 900px) {
          display: none;
        }
      }

      & > .search-pane__range-selector {
        @media screen and (max-width: 900px) {
          display: none;
        }
      }

      & > img {
        @media screen and (max-width: 900px) {
          display: block;
        }
      }
    }

    &:nth-child(5) {
      margin: 0;

      @media screen and (max-width: 1000px) {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
      }

      & > span {
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }

      & > .search-pane__range-selector {
        @media screen and (max-width: 1000px) {
          display: none;
        }
      }

      & > img {
        @media screen and (max-width: 1000px) {
          display: block;
        }
      }
    }

    &.primary {
      & > span {
        color: $yellow;
      }
    }

    & > span {
      display: block;
      width: 100%;
      color: $brownLight;
      text-transform: uppercase;
      text-align: center;
      margin: 0 0 $xs 0;
      @include applyFontSchema($labelMedium, $mulishFamily, $medium);
    }

    &-selector {
      width: min-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid $spaceWhite;
      border-radius: $s;
      padding: 0 $s 0 $sm;

      &.mw-200 {
        min-width: 200px;
        max-width: 200px;
        justify-content: space-between;

        & > span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      & > span {
        display: block;
        white-space: nowrap;
        margin: 0 $s 0 0;
        @include applyFontSchema($labelLarge, $mulishFamily, $medium);
      }
    }

    &-controllers {
      width: min-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &-hitbox {
      height: 100%;
      padding: $s 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &-amount {
      width: 24px;
      height: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $yellow;
      color: $whiteLight;
      @include applyFontSchema($bodyXSmall, $mulishFamily, $bold);
    }
  }

  &__free-search {
    width: 100%;
    min-width: 200px;
    max-width: 200px;
    padding: $s $sm;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid $yellow;
    border-radius: $s;

    & > img {
      margin: 0 $s 0 0;
    }

    & > input {
      width: 100%;
      background-color: transparent;
      font-size: 14px;
    }
  }
}

.advanced-filters {
  margin: 0 0 $m 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 $l;

  &__container {
    padding: $m;
    background-color: $whiteLight;
    box-shadow: 0px 0px 23px rgba(64, 59, 55, 0.18);
    border-radius: $s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .triangle {
    width: min-content;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 6px solid $whiteLight;
    box-shadow: 0px 0px 23px rgba(64, 59, 55, 0.18);
    margin: 0 $m 0 0;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  &__layout {
    width: min-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1120px) {
      flex-direction: column;
    }
  }

  &__slider-item {
    border: 1px solid $brownLight;
    padding: $sm $m;
    border-radius: $s;
    margin: 0 $sm 0 0;
    max-width: 230px;
    width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1120px) {
      margin: 0 0 $sm 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  &__label {
    color: $brown;
    margin: 0 0 $s 0;
    @include applyFontSchema($bodyMedium, $mulishFamily, $bold);
  }

  &__range {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > span {
      color: $brown;
      @include applyFontSchema($bodyMedium, $mulishFamily, $medium);
    }
  }
}

.range-filter {
  margin: 0 0 $m 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 $l;

  &__container {
    max-width: 200px;
    width: 200px;
    padding: $s $l;
    background-color: $whiteLight;
    box-shadow: 0px 0px 23px rgba(64, 59, 55, 0.18);
    border-radius: $s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .triangle {
    width: min-content;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 6px solid $whiteLight;
    box-shadow: 0px 0px 23px rgba(64, 59, 55, 0.18);

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  &__label {
    color: $brown;
    margin: 0 0 $s 0;
    @include applyFontSchema($bodyMedium, $mulishFamily, $bold);
  }

  &__range {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > span {
      color: $brown;
      @include applyFontSchema($bodyMedium, $mulishFamily, $medium);
    }
  }
}

.dd-filter {
  margin: 0 0 $m 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 $l;

  &__container {
    max-width: 200px;
    width: 200px;
    background-color: $whiteLight;
    box-shadow: 0px 0px 23px rgba(64, 59, 55, 0.18);
    border-radius: $s;

    &-layout {
      min-height: 245px;
      max-height: 245px;
      height: auto;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__search {
    width: 100%;
    padding: $s $sm;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $brownLight;

    & > img {
      margin: 0 $s 0 0;
    }

    & > input {
      width: 100%;
      background-color: transparent;
      font-size: 14px;
    }
  }

  & .triangle {
    width: min-content;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 6px solid $whiteLight;
    box-shadow: 0px 0px 23px rgba(64, 59, 55, 0.18);

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  &__item {
    padding: $sm $m;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    cursor: pointer;

    & > span {
      color: $brown;
      @include applyFontSchema($bodyMedium, $mulishFamily, $regular);
    }
  }
}
