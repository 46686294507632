@keyframes fadeInToBottomReversed {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(-200%);
  }
}
