.article {
  max-width: 346px;
  max-height: 335px;
  width: 346px;
  height: 335px;
  background-color: $white;
  border-radius: 16px;
  position: relative;

  z-index: 5;
  cursor: pointer;
  transition: all 1s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 420px) {
    max-width: 90%;
    width: 90%;
  }

  &:nth-child(1) {
    rotate: (-9.98deg);

    @media screen and (max-width: 1020px) {
      rotate: unset;
    }
  }

  &:nth-child(2) {
    transform: translateY(-20%);

    @media screen and (max-width: 1380px) {
      rotate: (9.98deg);
      transform: unset;
    }

    @media screen and (max-width: 1020px) {
      display: none;
    }
  }

  &:nth-child(3) {
    rotate: (9.98deg);

    @media screen and (max-width: 1380px) {
      display: none;
    }
  }

  & > img {
    width: 100%;
    height: 243.64px;
    border-radius: $m $m $zero $zero;

    @media screen and (max-width: 420px) {
      height: 190px;
    }
  }

  &__details {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
  }

  &__title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 72px;
    min-height: 72px;
    font-family: 'Mulish Regular';
    color: $brown;
    @include applyFontSchema($headlineSmall, $pfdFamily, $regular);

    @media screen and (max-width: 540px) {
      @include applyFontSchema($headlineSmall, $pfdFamily, $regular);
    }
  }

  &__publish {
    margin: 0 0 $sm 0;
    @include applyFontSchema($labelLarge, $mulishFamily, $medium);
  }
}
