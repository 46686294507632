.button-favorite {
  height: $c;
  width: $c;
  position: absolute;
  z-index: 6;
  cursor: pointer;
  right: $m;
  top: $m;

  @media screen and (max-width: 600px) {
    height: $xl;
    width: $xl;
  }
}
