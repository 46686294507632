.button-cwu {
  width: 146px;
  height: 146px;
  right: $l;
  bottom: $l;
  z-index: 10;
  position: fixed;
  animation: fadeInToTop 1.5s ease-in-out;

  &.search-activated {
    bottom: 111px;
  }

  @media screen and (max-width: 580px) {
    width: $xc;
    height: $xc;
    right: $m;
    bottom: $m;
  }

  &__animation {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__layout {
    width: $xxc;
    height: 100%;
    position: relative;
    margin: auto;
    z-index: 2;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;

    @media screen and (max-width: 580px) {
      width: 100%;
    }

    & > img {
      width: $l;
      height: $l;
      margin: 0 0 $xs 0;

      @media screen and (max-width: 580px) {
        width: $xl;
        height: $xl;
        margin: 0;
      }
    }
  }

  &__label {
    text-align: center;
    color: $whiteLight;
    text-transform: uppercase;
    @include applyFontSchema($labelMedium, $mulishFamily, $regular);

    @media screen and (max-width: 580px) {
      display: none;
    }
  }

  &__form {
    box-shadow: 0px 0px 23px rgb(64, 59, 55, 0.18);
    position: absolute;
    z-index: 10;
    width: 200%;
    max-width: 279px;
    bottom: 161px;
    right: 24px;
    background-color: $whiteLight;
    padding: $m;
    border-radius: $m;
    animation: fadeInToLeft 0.3s ease-in-out;

    @media screen and (max-height: 890px) {
      max-height: 280px;
    }

    & .form-for-request {
      @media screen and (max-height: 890px) {
        min-width: 100%;
        max-width: 100%;
        padding: 0 8px 0 0;
        max-height: 180px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }

    @media screen and (max-width: 580px) {
      bottom: 80px;
      right: 0;
      max-width: 260px;
      min-width: 260px;
    }

    & > span {
      display: block;
      margin: 0 0 $m 0;
      @include applyFontSchema($titleMedium, $pfdFamily, $bold);
    }

    & .triangle {
      position: absolute;
      bottom: -6px;
      right: 42px;
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 6px solid $whiteLight;

      @media screen and (max-width: 580px) {
        right: 25px;
      }
    }
  }

  &__form-item {
    padding: $m $xs;
    border-top: 1px solid $white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-direction: row;
    color: $brown;
    cursor: pointer;
    @include applyFontSchema($labelMedium, $mulishFamily, $medium);

    &:last-child {
      border-bottom: 1px solid $white;
    }
  }

  &__form-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $s $xs;
    position: relative;
    margin: 0 0 $s 0;

    & .close {
      cursor: pointer;
      position: absolute;
      right: 0;
    }

    & img {
      cursor: pointer;
    }

    & > span {
      margin: 0 auto;
      color: $brown;
      @include applyFontSchema($titleSmall, $mulishFamily, $medium);
    }
  }

  &__form-thanks {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;

    & > img {
      cursor: pointer;
    }
  }

  &__form-thanks-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: $l 0;

    & > span {
      color: $brown;
      @include applyFontSchema($labelLarge, $mulishFamily, $medium);
    }

    & > img {
      margin: 0 0 $s 0;
    }
  }
}
