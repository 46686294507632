.algarve {
  width: 100%;
  overflow: hidden;
  display: flex;
  margin: 112px 0 0 0;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;

  & .content-header {
    align-items: flex-start;

    @media screen and (max-width: 820px) {
      align-items: center;
    }

    @media screen and (max-width: 760px) {
      margin: 0;
    }
  }

  & h1 {
    margin: 60px 0 0 60px;
    @include applyFontSchema($titleLarge, $pfdFamily, $bold);

    @media screen and (max-width: 820px) {
      margin: 0 0 32px 0;
      text-align: center;
      @include applyFontSchema($titleSmall, $pfdFamily, $bold);
    }
  }

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }

  @include applyFontSchema($displayMedium, $pfdFamily, $bold);

  &__block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 412px;
    bottom: 495px;
    height: 0;
    animation: moveUpDown 2s infinite ease-in-out;

    @media screen and (max-width: 450px) {
      padding: 0 10px;
    }
  }

  &__block-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $whiteLight;
    border-radius: 31px;
    padding: $s;
    margin: 0 0 $sm 0;
    text-align: center;
    height: 100%;
    min-height: 66px;

    & > span {
      @include applyFontSchema($titleSmall, $pfdFamily, $bold);
    }
  }

  &__header {
    display: flex;
    width: 100%;
    max-width: 80%;
    height: 100%;

    @media screen and (max-width: 750px) {
      max-width: 80%;
      flex-direction: column;
    }

    .content-header {
      @media screen and (max-width: 780px) {
        margin: 0 auto $one auto;
      }
    }
  }

  & > img {
    width: 100%;
    height: 100vh;
    min-width: 2560px;
    min-height: 693px;
    max-width: 2560px;
    max-height: 693px;
    object-position: center;
  }

  & > h2 {
    margin: auto 322px;
    @include applyFontSchema($headlineLarge, $mulishFamily, $regular);
  }
}

.offers {
  overflow: hidden;
  width: 100%;

  &__sample {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1160px) {
      flex-direction: column;
    }

    & > img {
      min-width: 50%;
      width: 100%;

      @media screen and (max-width: 1160px) {
        max-width: 100%;
      }
    }

    &.even {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 1160px) {
        flex-direction: column;
      }
    }
  }

  &__title {
    width: 100%;
    padding: 0 5% 0 10%;

    @media screen and (max-width: 1160px) {
      padding: 30px 30px;
    }

    & > h3 {
      color: $yellow;
      margin: 0 0 $s 0;
      @include applyFontSchema($headlineSmall, $pfdFamily, $bold);

      @media screen and (max-width: 1160px) {
        @include applyFontSchema($headlineLarge, $pfdFamily, $bold);
      }

      @media screen and (max-width: 400px) {
        @include applyFontSchema($headlineSmall, $pfdFamily, $bold);
      }
    }

    & > span {
      white-space: pre-line;
      color: $brown;
      @include applyFontSchema($bodyMedium, $mulishFamily, $medium);

      @media screen and (max-width: 1160px) {
        @include applyFontSchema($titleSmall, $mulishFamily, $regular);
      }

      @media screen and (max-width: 400px) {
        @include applyFontSchema($titleSmall, $mulishFamily, $regular);
      }
    }
  }
}

.bottom {
  overflow: hidden;
  width: 100%;
  height: 100;
  padding: 64px 10%;

  @media screen and (max-width: 1300px) {
    padding: 70px 10%;
  }

  @media screen and (max-width: 1160px) {
    padding: 50px 5%;
  }

  @media screen and (max-width: 500px) {
    padding: 30px 5%;
  }

  & > h2 {
    text-align: center;
    @include applyFontSchema($headlineSmall, $mulishFamily, $regular);

    @media screen and (max-width: 500px) {
      @include applyFontSchema($titleMedium, $mulishFamily, $regular);
    }
  }
}
